




























import { Component } from 'vue-property-decorator';
import Vue from 'vue';

import './scss/FiltersPreview.scss';

@Component({
    name: 'FiltersPreview',
    components: { },
})

export default class FiltersPreview extends Vue {
    $refs!: {
        filterPopup: HTMLFormElement,
    }

    filtersData: any = [];

    get filtersPreviewForDisplay() {
        const output: any = [];
        this.filtersData.forEach((item) => {
            if (this.showSection(item) && (item.type === 'checkboxes' || item.type === 'select')) {
                item.selected.forEach((selected) => {
                    output.push({
                        section: item.title,
                        selected: selected,
                        ratio: item.ratio,
                    })
                })
            }
            if (this.showSection(item) && item.type === 'dateRange') {
                if (item.datesRange.dateFrom) {
                    output.push({
                        section: item.title,
                        selected: item.datesRange.dateFrom.replaceAll('-', '.'),
                        dateRange: 'from',
                    });
                }
                if (item.datesRange.dateTo) {
                    output.push({
                        section: item.title,
                        selected: item.datesRange.dateTo.replaceAll('-', '.'),
                        dateRange: 'to',
                    });
                }
            }
        });

        return output;
    }

    textForFilterPreview(item) {
        if (item.dateRange) {
            const datePrefix = item.dateRange === 'to' ? 'до' : 'от';
            return `${item.section}: ${datePrefix} ${item.selected}`;
        } else if (item.ratio && item.ratio.length) {
            return item.ratio.filter(val => val.value === item.selected)[0].label;
        }
        return item.selected;
    }

    setFiltersData(data) {
        this.filtersData = data;
    }

    showSection(item) {
        if (item.type === 'checkboxes' || item.type === 'select') {
            return !!item.selected.length;
        }
        if (item.type === 'dateRange') {
            return item.datesRange.dateFrom || item.datesRange.dateTo;
        }
        return false;
    }

    resetFilter(filter) {
        this.filtersData.forEach(item => {
            if (item.title === filter.section) {
                if (item.type === 'dateRange') {
                    if (filter.dateRange === 'from') {
                        item.datesRange.dateFrom = null;
                    }
                    if (filter.dateRange === 'to') {
                        item.datesRange.dateTo = null;
                    }
                    this.$emit('reset-filter-date', {
                        section: filter.section,
                        datesRange: item.datesRange,
                    });
                } else {
                    const newSelected = item.selected.filter(selected => selected !== filter.selected);
                    item.selected = newSelected;
                    this.$emit('reset-filter', {
                        section: filter.section,
                        selected: newSelected,
                    });
                }
            }
        })
    }
}
